import { Controller } from "@hotwired/stimulus"
import { currentAccountId } from "../helpers/current_helpers"

export default class extends Controller {
  static targets = ["inboxEntry", "refresh", "newTicketWithHigherAgony"]

  static values = {
    autoRefresh: Boolean
  }

  inboxEntryTargetConnected(inboxEntryElm) {
    const accountId = inboxEntryElm.getAttribute("data-account-id") 

    if (accountId && accountId != currentAccountId()) {
      // NOTE: Notification was meant to someone else.
      return
    }

    const inboxEntryId = inboxEntryElm.dataset.inboxEntryId
    if (document.querySelector(`[data-entry-id="${inboxEntryId}"]`)) {
      // NOTE: Prevent duplicates if entry is already on the page.
      return
    }

    const agony = parseFloat(inboxEntryElm.getAttribute("data-agony"))
    const highestAgony = this.#highestAgonyOnPage()
    const inboxEntryHasHigherAgonyThanOthersOnPage = agony > highestAgony

    if (inboxEntryHasHigherAgonyThanOthersOnPage) {
      Telescope.Notification.show({
        title: "New ticket!",
        body: "There is new a customer waiting in your inbox."
      })
    }

    this.#refresh()
  }

  newTicketWithHigherAgonyTargetConnected() {
    Telescope.Notification.show({
      title: "New ticket!",
      body: "There is new a customer waiting in your inbox."
    })
  }

  #highestAgonyOnPage() {
    const agonies =
      Array.from(document.querySelectorAll("[data-entry-id][data-agony]"))
        .map(elm => elm.getAttribute("data-agony"))
        .map(agony => parseFloat(agony))
        .filter(agony => !Number.isNaN(agony))

    return Math.max(...agonies)
  }

  #refresh() {
    if (this.hasRefreshTarget) {
      this.refreshTarget.classList.remove("hidden")
    }

    if (this.autoRefreshValue) {
      Turbo.visit(location.href, { action: "replace" })
    }
  }
}
